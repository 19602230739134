import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import imgHopeProve from "../../assets/images/hope-probe/instruments/hope-probe.png"
import imgMarsProperties from "../../assets/images/hope-probe/instruments/mars-properties.png"
import imgBlue11 from "../../assets/images/hope-probe/instruments/blue-11.png"
import imgBlue12 from "../../assets/images/hope-probe/instruments/blue-12.png"
import imgBlue13 from "../../assets/images/hope-probe/instruments/blue-13.png"
import imgRed2 from "../../assets/images/hope-probe/instruments/red-2.png"
import imgRed1 from "../../assets/images/hope-probe/instruments/red-1.png"
import imgGreen1 from "../../assets/images/hope-probe/instruments/green-2.png"
import imgEmirs from "../../assets/images/hope-probe/instruments/emirs.png"
import imgExi from "../../assets/images/hope-probe/instruments/exi.png"
import imgEmus from "../../assets/images/hope-probe/instruments/emus.png"

const instrumentsPage = () => (
  <Layout>
    <SEO title="Instruments" />
    <main>
      <div className="uk-cover-container" data-uk-height-viewport="offset-top: true; offset-bottom: 60">
        <div className="uk-overlay uk-position-cover header-overlay"></div>
      </div>
      <section id="about-emm" style={{ position: "relative", paddingLeft: "0"}}>
          <div className="standard-container transparent-bg pb5 pa4">
              <div className="flex flex-column flex-row-ns">
                <div className="w-100 w-50-ns">
                  <img src={imgHopeProve} className="w-100" alt=""/>
                </div> 
                <div className="w-100 w-50-ns pa4-ns z-index-10">
                  <div>
                    <h2 className="relative">
                      INSTRUMENTS
                      <div className="text-underline-heavy"></div>
                    </h2>
                    <p className="mt5">
                    The Emirates Mars Mission “Hope Probe” is tasked to provide the first ever complete picture of the Martian atmosphere. Three stat-of-the-art science instruments have been designed to study the different aspects of the Martian Atmosphere.                    </p>
                    
                  </div>
                </div> 
              </div>
          </div>
          <div className="uk-position-relative pa4">
            <img src={imgMarsProperties} className="relative absolute-ns w-100 w-50-ns" style={{maxWidth: "720px"}} alt=""/>
          <div className="standard-container pb4">
          <div className="flex flex-column flex-row-ns justify-end items-center pv4" style={{borderBottom: "1px solid red"}}>
                <div className="ph4 w-100 w-20-ns uk-text-center">
                  <img src={imgRed2} height="140px" width="140px" alt=""/>
                  <p className="text-highlight-brown mt0 pt0 mb0 pb0">
                  INFRARED
                  </p>
                </div>
                <div className="pr4 pl0 flex w-100 w-30-ns items-center" style={{backgroundColor: "#272727", borderRadius: "100px 0px 0px 100px"}}>
                    <img src={imgBlue11} height="150px" width="150px" alt=""/>
                    <p className="uk-text-small uk-text-bold text-light ml2 mv0">
                    Emirates Mars Infrared Spectrometer 
                      <span className="text-highlight-red">
                      (EMIRS)
                      </span>
                    </p>
                </div>
              </div>
              <div className="flex flex-column flex-row-ns justify-end items-center pv4" style={{borderBottom: "1px solid red"}}>
                <div className="ph4 w-100 w-20-ns uk-text-center">
                  <img src={imgRed1} height="140px" width="140px" alt=""/>
                  <p className="text-highlight-brown mt0 pt0 mb0 pb0">
                  HIGH RESOLUTION PHOTOS
                  </p>
                </div>
                <div className="pr4 pl0 flex w-100 w-30-ns items-center" style={{backgroundColor: "#272727", borderRadius: "100px 0px 0px 100px"}}>
                    <img src={imgBlue12} height="150px" width="150px" alt=""/>
                    <p className="uk-text-small uk-text-bold text-light ml2">
                    EMIRATES EXPLORATION IMAGER 
                      <span className="text-highlight-red">
                      (EXI)
                      </span>
                    </p>
                </div>
              </div>
              <div className="flex flex-column flex-row-ns justify-end items-center pv4">
                <div className="ph4 w-100 w-20-ns uk-text-center">
                  <img src={imgGreen1} height="140px" width="140px" alt=""/>
                  <p className="text-highlight-brown mt0 pt0 mb0 pb0">
                  ULTRA VIOLET
                  </p>
                </div>
                <div className="pr4 pl0 flex w-100 w-30-ns items-center" style={{backgroundColor: "#272727", borderRadius: "100px 0px 0px 100px"}}>
                    <img src={imgBlue13} height="150px" width="150px" alt=""/>
                    <p className="uk-text-small uk-text-bold text-light ml2">
                    EMIRATES MARS ULTRAVIOLET SPECTROMETER 
                      <span className="text-highlight-red">
                      (EMUS)
                      </span>
                    </p>
                </div>
              </div>  
          </div> 
          </div>
          <div className="standard-container transparent-bg pb6 pa4" style={{marginBottom: "100px"}}>
            <div className="standard-bg" style={{maxWidth: "900px", margin: "auto"}}>
              <div className="relative pa4">
                {/* <a href="#emirs" className="uk-float-right" type="button" data-uk-icon="icon: plus-circle" data-uk-toggle="target: #emirs; animation: uk-animation-fade" style={{color: "red"}}></a> */}
                <div className="flex flex-column flex-row-ns">
                  <div className="w-100 w-40-ns ph4">

                  <img src={imgEmirs} alt=""/>
                  </div>
                  <div className="w-100 w-60-ns">
                      <h2 style={{color: "#A1542B !important"}}>
                            EMIRS
                      </h2>
                      <div className="uk-text-small uk-text-bold text-light">
                      Emirates Mars Infrared Spectrometer
                      </div>
                      <ul className="uk-list-circle">
                          <li>
                          Studies the lower atmosphere of the red planet in the infrared band 
                          </li>
                          <li>
                          Measures the global distribution of dust, ice clouds, water vapours, and temperature profiles 
                          </li>
                          <li>
                          Provides the linkages from the lower to the upper atmosphere in conjunction with EMUS and EXI observations
                          </li>
                      </ul>
                  </div>
                </div>
              </div>
              <div id="emirs" className="pa4">
                <p>The EMIRS instrument is an interferometric thermal infrared spectrometer that will give a better understanding of the energy balance in the current Martian climate by characterizing the state of the lower Martian atmosphere and the processes that are driving the global circulation.  
                </p>
                <p>
                Understanding the energy balance will help in identifying the sources and sinks of energy globally and how the lower atmosphere responds to solar forcing diurnally and seasonally. Specifically, the EMIRS instrument will look at the thermal state in the lower atmosphere, the geographical distribution of dust, water vapor and water ice, as well as the three-dimensional thermal structure of the Martian atmosphere and its variability on sub-seasonal timescales. The EMIRS instrument has a rotating mirror that will allow the instrument to do scans of Mars.
                </p>
              </div>
            </div>
            <div className="mv5">
            <div className="standard-bg" style={{maxWidth: "900px", margin: "auto"}}>
              <div className="relative pa4">
                {/* <a href="#exi" className="uk-float-right" type="button" data-uk-icon="icon: plus-circle" data-uk-toggle="target: #exi; animation: uk-animation-fade" style={{color: "red"}}></a> */}
                <div className="flex flex-column flex-row-ns">
                  <div className="w-100 w-40-ns ph4">

                  <img src={imgExi} alt=""/>
                  </div>
                  <div className="w-100 w-60-ns">
                      <h2 style={{color: "#A1542B !important"}}>
                      EXI
                      </h2>
                      <div className="uk-text-small uk-text-bold text-light">
                      EMIRATES EXPLORATION IMAGER
                      </div>
                      <ul className="uk-list-circle">
                          <li>
                          Studies the lower atmosphere of the red planet in visible and ultraviolet bands. 

                          </li>
                          <li>
                          Captures high-resolution images of Mars 
                          </li>
                          <li>
                          Measures the optical depth of water ice in the atmosphere
                          </li>
                          <li>
                          Measures the column abundance of ozone 
                          </li>
                          <li>
                          Provides visible images of Mars during atmosphere
                          </li>
                      </ul>
                  </div>
                </div>
              </div>
              <div id="exi" className="pa4">
                <p>
                The EXI instrument is a multi-wavelength radiation tolerant camera, EXI is capable of taking 12-megapixel images while maintaining the radiometric calibration needed for detailed scientific analysis. The instrument is being developed jointly by the Laboratory for Atmospheric and Space Physics (LASP) and Mohammed Bin Rashid Space Centre (MBRSC). The flexible readout modes allow customization of resolution, regions of interest and frame rate to meet the needs of the user and available memory and bandwidth. The lens system is a double lens assembly with a separate UV and VIS optical paths. The type of lens used is a double Gauss variant, a compound type lens used to provide low distortion high resolution imaging of Mars. The low focal ratio makes it possible to use very short exposure times to capture stable images during periapsis, while fitting both lens systems into a compact package. The image sensor is a 12-megapixel 12-bit monochrome CMOS array 4:3 imager. On-chip binning and region of interest windowing of the pixel array can be used to tailor image size and resolution as needed, minimizing the data rate. At full resolution, the sensor is capable of capturing 180 frames per second, enabling the possibility of 4K movie mode if desired. The use of discrete filters gives improved resolution in each colour compared with that using an RGB Bayer array. In addition, it provides more precise instrumental characterization, i.e., lower radiometric uncertainty of scientific imaging (needed for high accuracy retrievals of cloud optical depth). For the UV lens system, short-wavelength UV-C (245 – 275 nm) and long-wavelength UV-A (305 – 335 nm) will be cover ed. As for the VIS lens system, it will cover the bands; Red (625 – 645 nm), Green (506 - 586 nm) and Blue (405 – 469 nm). 
                </p>
              </div>
            </div>
            </div>
            
            <div className="standard-bg" style={{maxWidth: "900px", margin: "auto"}}>
              <div className="relative pa4">
                {/* <a href="#emus" className="uk-float-right" type="button" data-uk-icon="icon: plus-circle" data-uk-toggle="target: #emus; animation: uk-animation-fade" style={{color: "red"}}></a> */}
                <div className="flex flex-column flex-row-ns">
                  <div className="w-100 w-40-ns ph4">

                  <img src={imgEmus} alt=""/>
                  </div>
                  <div className="w-100 w-60-ns">
                      <h2 style={{color: "#A1542B !important"}}>
                      EMUS
                      </h2>
                      <div className="uk-text-small uk-text-bold text-light">
                      EMIRATES MARS ULTRAVIOLET SPECTROMETER
                      </div>
                      <ul className="uk-list-circle">
                          <li>
                          Detects ultraviolet wavelength 

                          </li>
                          <li>
                          Determines the abundance and variability of carbon monoxide and oxygen in the thermosphere on sub-seasonal timescales 
                          </li>
                          <li>
                          Calculates the three-dimensional structure and variability of oxygen and hydrogen in the exosphere 
                          </li>
                          <li>
                          Measures the relative changes in the thermosphere

                          </li>
                      </ul>
                  </div>
                </div>
              </div>
              <div id="emus" className="pa4">
                <p>
                The EMUS instrument is a far ultraviolet spectrometer that is jointly developed by the Laboratory for Atmospheric and Space Physics (LASP) and Mohammed Bin Rashid Space Centre (MBRSC). The instrument is designed to fulfil two out of the four EMM investigations (investigation 3 and 4) which relate to thermospheric (100-200 km altitude) and exospheric (> 200 km altitude) variability, respectively.  EMUS will have a spatial resolution of 0.36o, selectable spectral resolution of 1.3 nm and 1.8 nm and a spectral range of 100 – 170 nm to make the required observations of ultraviolet emissions of hydrogen (H), oxygen (O), and carbon monoxide (CO).  The spectral resolutions are chosen in order to distinguish between the emissions of interest from other bright emissions (specifically oxygen emission at 135.6 nm from 130.4 nm) and to resolve CO emissions.
                </p>
              </div>
            </div>
          </div> 
      </section>
    </main>
  </Layout>
)

export default instrumentsPage
